import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import * as Sentry from "@sentry/react";
import { ErrorBoundary } from "react-error-boundary";
import posthog from "posthog-js";
import App from "./components/App";
import ErrorFallback from "./components/ErrorFallback/ErrorFallback";

if (window.location.hostname !== "localhost") {
  Sentry.init({
    dsn: "https://84c220c5005bacd47fa708fe77836970@o4507779889692672.ingest.de.sentry.io/4507779902799952",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 0.1, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: [/^(?!localhost)/, /^https:\/\/app\.zawadi\.africa/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  posthog.init("phc_CJt7WTFGG5Qajbc7XFTN3UJ8iZAldvuio78SPTvUS5R", {
    api_host: "https://us.i.posthog.com",
    person_profiles: "always", // or 'always' to create profiles for anonymous users as well
  });
}

// Handle unhandled promise rejections
window.addEventListener("unhandledrejection", event => {
  console.error("Unhandled promise rejection:", event.reason);
  // add custom error handling here
});

// Handle other unhandled errors
window.addEventListener("error", event => {
  console.error("Unhandled error:", event.error);
  // add custom error handling here
});

const logError = (error, info) => {
  // Log to Sentry if in production
  if (window.location.hostname !== "localhost") {
    Sentry.captureException(error, { extra: info });
  }
  // Log to console in development
  console.error("Error:", error);
  console.error("Error Info:", info);
};

ReactDOM.render(
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onError={logError}
    onReset={() => {
      // Reload app
      window.location.reload();
    }}
  >
    <App />
  </ErrorBoundary>,
  document.getElementById("root")
);
