/* eslint-disable react/jsx-curly-newline */
import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import Select from "react-select";
import {
  dealerIdTypes,
  countryCodes,
  ricaNetworks,
  selectFieldProps,
  textFieldProps,
} from "../shared/constants";
import { useAuth } from "../../contexts/AuthContext";
import * as API from "../../api";

const yup = require("yup");

/**
 * @function RicaPage
 * @description A page for RICA'ing a sim card.
 * @returns {React.Component} A React component.
 */
export default function RicaPage() {
  const { currentUser } = useAuth();

  // Form State
  const [state, setState] = useState({});
  const [network, setNetwork] = useState(null);
  const [resultMessage, setResultMessage] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleConfirmClose = resetForm => {
    if (resetForm) {
      window.location.reload();
    } else {
      setConfirmOpen(false);
    }
  };

  const handleSubmit = e => {
    setLoading(true);
    e.preventDefault();

    const last4SIM = state.reference_number.slice(-4);

    const info = {
      ...state,
      last_4_sim: last4SIM,
      network,
      user_id: currentUser.id,
    };

    const schema = yup.object().shape({
      network: yup.string().required("Network is required"),
      reference_number: yup.number().required("SIM Number is required"),
      id_type: yup.string().required("ID Type is required"),
      country_code: yup.string().required("ID Nationality is required"),
      id_number: yup.string().required("ID Number is required"),
      full_name: yup.string().required("Name is required"),
      surname: yup.string().required("Surname is required"),
      address_1: yup.string().required("Address is required"),
      city_town: yup.string().required("City is required"),
      suburb: yup.string().required("Suburb is required"),
      postal_code: yup.string().required("Postal Code is required"),
      residential_country: yup.string().required("Country is required"),
    });

    schema
      .validate(info)
      .then(() => {
        API.rica(info)
          .then(res => {
            if (res.data.code === 200) {
              setResultMessage(res.data.message?.result);
              setLoading(false);
              setConfirmOpen(true);
            }
          })
          .catch(err => {
            setResultMessage(err.message);
            setLoading(false);
            setConfirmOpen(true);
          });
      })
      .catch(err => {
        setResultMessage(err.message);
        setLoading(false);
        setConfirmOpen(true);
      });
  };

  useEffect(() => {
    setState({
      ...state,
      country_code: countryCodes[0].value,
      residential_country: countryCodes[0].value,
      id_type: dealerIdTypes[0].value,
    });
  }, []);

  return (
    <div style={{ marginBottom: 30 }}>
      <h1>RICA A SIM Card</h1>
      <form>
        <Typography>Network</Typography>
        <Select
          options={ricaNetworks}
          {...selectFieldProps}
          placeholder="Select Network ..."
          onChange={v => setNetwork(v.value)}
          required
        />
        <br />
        <TextField
          {...textFieldProps}
          required
          label="SIM Number (ICCID)"
          value={state.reference_number}
          onChange={e =>
            setState({ ...state, reference_number: e.target.value })
          }
        />
        <Typography>ID Type</Typography>
        <Select
          options={dealerIdTypes}
          {...selectFieldProps}
          onChange={v => setState({ ...state, id_type: v.value })}
          defaultValue={dealerIdTypes[0]}
          placeholder="ID Type"
          required
        />
        <br />
        <Typography>ID Nationality</Typography>
        <Select
          options={countryCodes}
          {...selectFieldProps}
          defaultValue={countryCodes[0]}
          onChange={v => setState({ ...state, country_code: v.value })}
          placeholder="ID Nationality"
          required
        />
        <br />
        <TextField
          {...textFieldProps}
          required
          label="ID Number"
          value={state.id_number}
          onChange={e => setState({ ...state, id_number: e.target.value })}
        />
        <TextField
          {...textFieldProps}
          required
          label="Name"
          value={state.full_name}
          onChange={e => setState({ ...state, full_name: e.target.value })}
        />
        <TextField
          {...textFieldProps}
          required
          label="Surname"
          value={state.surname}
          onChange={e => setState({ ...state, surname: e.target.value })}
        />
        <b />
        <b />
        <h3>Residential Street Address (Individual)</h3>
        <TextField
          {...textFieldProps}
          required
          label="Address Line 1"
          value={state.address_1}
          onChange={e => setState({ ...state, address_1: e.target.value })}
        />
        <TextField
          {...textFieldProps}
          label="Address Line 2"
          value={state.address_2}
          onChange={e => setState({ ...state, address_2: e.target.value })}
        />
        <TextField
          {...textFieldProps}
          label="Address Line 3"
          value={state.address_3}
          onChange={e => setState({ ...state, address_3: e.target.value })}
        />
        <TextField
          {...textFieldProps}
          required
          label="City / Town"
          value={state.city_town}
          onChange={e => setState({ ...state, city_town: e.target.value })}
        />
        <TextField
          {...textFieldProps}
          required
          label="Suburb"
          value={state.suburb}
          onChange={e => setState({ ...state, suburb: e.target.value })}
        />
        <TextField
          {...textFieldProps}
          required
          label="Postal Code"
          value={state.postal_code}
          onChange={e => setState({ ...state, postal_code: e.target.value })}
        />

        <Select
          options={countryCodes}
          {...selectFieldProps}
          defaultValue={countryCodes[0]}
          onChange={v => setState({ ...state, residential_country: v.value })}
          placeholder=" Residential Country"
          required
        />
        <br />
        <br />
        <Button
          disabled={loading || !state.reference_number}
          type="submit"
          variant="contained"
          fullWidth
          color="primary"
          onClick={handleSubmit}
        >
          {loading ? "Loading..." : "Submit RICA"}
        </Button>
      </form>
      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleConfirmClose}
      >
        <DialogContent>
          <Typography>{resultMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={() => {
              handleConfirmClose(false);
            }}
            variant="contained"
            color="primary"
          >
            Close & Keep Form Value
          </Button>
          <Button
            disabled={loading}
            onClick={() => {
              handleConfirmClose(true);
            }}
            variant="contained"
            color="primary"
          >
            Close & Reset Form
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
