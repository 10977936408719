/* eslint-disable import/order */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-danger */
import React, { useState, useEffect } from "react";
import { Switch, Link, useHistory } from "react-router-dom";
import { makeStyles, useTheme, darken } from "@material-ui/core/styles";
import {
  Badge,
  Divider,
  Typography,
  Box,
  Drawer,
  AppBar,
  Toolbar,
  CssBaseline,
  IconButton,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  Menu,
  Card,
  DialogContentText,
  Container,
} from "@material-ui/core";
import { useMediaQuery, CircularProgress } from "@mui/material";
import clsx from "clsx";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faIdCard,
  faSignOutAlt,
  faEnvelope,
  faGraduationCap,
  faUser,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DealerNotifications from "./dealercomps/notifications/DealerNotifications";
import topBg from "../img/appBarBg.png";
import sideBg from "../img/menuBg-min.png";
import logo from "../img/newZims3.png";
import { useSB } from "../contexts/SupabaseContext";
import DealerRoutes from "./routes/DealerRoutes";
import { useAuth } from "../contexts/AuthContext";
import checkUserStatus from "./shared/functions/checkUserStatus";
import PendingAccount from "./authenticate/PendingAccount";

/**
 * DealerMenu is a component that renders the main menu for the dealer.
 *
 * It is a persistent drawer that is open by default on larger screens and
 * closed by default on smaller screens. The drawer is toggleable by clicking the
 * menu button in the top left corner of the app bar.
 *
 * The component contains a list of items that are conditionally rendered based
 * on the current user's rank. If the user is an agent, the list of items is
 * different than if the user is a dealer or higher.
 *
 * The component also contains a link to the dealer's profile and a button to
 * logout.
 *
 * The component uses the DealerRoutes component to render the main content
 * area. The DealerRoutes component is a switch that renders different routes
 * based on the current path.
 *
 * @returns {React.ReactElement} The DealerMenu component.
 */
export default function DealerMenu() {
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { logout, currentUser } = useAuth();
  const history = useHistory();
  const [notifAnchorEl, setNotifAnchorEl] = useState(null);
  const [showAlertsModal, setShowAlertsModal] = useState(false);
  const [activeAlert, setActiveAlert] = useState(false);
  const [acknowledgingAlert, setAcknowledgingAlert] = useState(false);
  const [unseenAlerts, setUnseenAlerts] = useState([]);
  const [allAlerts, setAllAlerts] = useState([]);
  const [showActiveAlert, setShowActiveAlert] = useState(false);
  const [loadingAlerts, setLoadingAlerts] = useState(false);
  const { GetTableWhere, updateRow } = useSB();
  const [currentUserTitle, setCurrentUserTitle] = useState("");
  const [currentUserRank, setCurrentUserRank] = useState("");
  const [showTrainingAlert, setShowTrainingAlert] = useState(false);
  const [staticUserString, setStaticUserString] = useState("");
  const drawerWidth = 240;
  const isMobile = useMediaQuery("(max-width:600px)");

  const useStyles = makeStyles(() => ({
    userDetails: {
      display: "flex",
      color: "#fff",
      alignItems: "center",
      padding: "1rem 1.25rem",
      backgroundColor: theme.palette.primary.main,
    },
    detailsAvatar: {
      display: "flex",
      width: "2rem",
      height: "2rem",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100%",
      backgroundColor: darken(theme.palette.primary.main, 0.2),
    },
    icons: {
      color: "#fff",
      marginLeft: theme.spacing(2),
    },
    menuText: {
      color: "#fff",
      textDecoration: "none",
    },
    subMenuItem: {
      paddingLeft: 55,
    },
    root: {
      display: "flex",
    },
    appBar: {
      backgroundColor: "#fff",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      "& .MuiDrawer-paper": { borderWidth: 0 },
    },
    drawerPaper: {
      width: isMobile ? "100%" : drawerWidth,
      background: `url(${sideBg})`,
      backgroundSize: "cover",
    },
    drawerHeader: {
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      // padding: theme.spacing(1),
      // transition: theme.transitions.create("margin", {
      //   easing: theme.transitions.easing.sharp,
      //   duration: theme.transitions.duration.leavingScreen,
      // }),
      marginLeft: -drawerWidth,
    },
    mobileContent: { padding: theme.spacing(2) },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    list: {
      backgroundColor: darken(theme.palette.primary.main, 0.2),
    },
    hoverColor: {
      "&:hover": {
        background: "rgba(255, 255, 255, .05)",
      },
    },
  }));
  const classes = useStyles();
  const [showWaitingStatus, setShowWaitingStatus] = useState(false);
  const [loading, setLoading] = useState(true);

  /**
   * Retrieves all active alerts for the current user from the database.
   *
   * This function takes no arguments.
   *
   * It returns a promise that resolves with an array of alerts, each
   * containing the alert's title, description, and active status.
   * The returned array is deduplicated by alertId, so each alert appears
   * only once in the array.
   *
   * If there is an error, it logs an error message to the console.
   *
   * @return {Promise<Array<{title: string, description: string, active: boolean}>>}
   */
  async function GetAllAlerts() {
    return await GetTableWhere(
      "global_alerts",
      ["uid", "eq", currentUser.id],
      ["active", "eq", true]
    )
      .then(doc => {
        // get unique list items based on alertId
        // Create a Map to store unique items based on alertId
        const uniqueItemsMap = new Map();

        // Iterate through the jsonData array
        doc.forEach(item => {
          // Use the alertId as the key in the Map to store only one item per alertId
          uniqueItemsMap.set(item.new_alert_id, item);
        });

        // Convert the Map values back to an array
        const uniqueItemsArray = Array.from(uniqueItemsMap.values());

        return uniqueItemsArray;
      })
      .catch(err => {
        // eslint-disable-next-line
        console.error(err, "getAllAlerts", {});
      });
  }

  useEffect(() => {
    const rankNames = [
      "Admin",
      "Super Dealer",
      "Dealer",
      "Sales Manager",
      "Tier 2 Team Leader",
      "Tier 1 Team Leader",
      "Assistant Team Leader",
      "Agent",
    ];

    if (currentUser && JSON.stringify(currentUser) !== staticUserString) {
      setStaticUserString(JSON.stringify(currentUser));
      const userRank = currentUser.rank;
      setCurrentUserTitle(
        `${currentUser.contact_name} ${currentUser.contact_surname}`
      );
      setCurrentUserRank(
        userRank && rankNames[userRank - 1] ? rankNames[userRank - 1] : ""
      );

      checkUserStatus(currentUser).then(result => {
        if (!result.status) {
          setShowWaitingStatus(true);
        } else {
          setShowWaitingStatus(false);
          getAlertsFunction();
        }
        setLoading(false);
      });
    }
  }, [currentUser]);

  const drawerItems = [
    { title: "Dashboard", path: "/", icon: faTachometerAlt },
    { title: "Your Team", path: "/manage-dealers", icon: faIdCard },
    { title: "Zawadi University", path: "/training", icon: faGraduationCap },
    { title: "Alerts", path: "/alerts", icon: faEnvelope },
    { title: "Profile", path: "/profile", icon: faUser },
  ];

  const agentDrawerItems = [
    {
      title: showWaitingStatus ? "Home" : "Dashboard",
      path: "/",
      icon: showWaitingStatus ? faHome : faTachometerAlt,
    },
    { title: "Zawadi University", path: "/training", icon: faGraduationCap },
    { title: "Profile", path: "/profile", icon: faUser },
  ];

  const toggleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleLogOut = async () => {
    await logout();
    history.push("/login");
  };

  // Training Module Check

  const getAlertsFunction = () => {
    setLoadingAlerts(true);
    GetAllAlerts(currentUser.id)
      .then(res => {
        const unseenAlertArray = [];
        const allAlertArray = [];

        if (res.length === 0) {
          setShowAlertsModal(false);
          setLoadingAlerts(false);
        }

        res.forEach((item, index) => {
          allAlertArray.push(item);
          if (item.read === false) {
            unseenAlertArray.push(item);
          }
          if (index === res.length - 1) {
            setUnseenAlerts(unseenAlertArray);
            if (unseenAlertArray.length > 0) {
              setShowAlertsModal(true);
            } else {
              setShowAlertsModal(false);
            }
            setLoadingAlerts(false);
            setAllAlerts(allAlertArray);
          }
        });
      })
      .catch(err => {
        console.error(err);
      });
  };

  const showAlertFunction = alert => {
    setActiveAlert(alert);
    setShowActiveAlert(true);
  };

  return (
    <div className={classes.root}>
      <Dialog
        maxWidth="sm"
        fullWidth
        PaperProps={{
          elevation: 10, // Increase the shadow level
          style: {
            borderRadius: "16px", // Set the card rounding
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Initial shadow for cards
          },
        }}
        open={showTrainingAlert}
        onClose={() => {
          // No close
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have not completed all the training modules. Please complete all
            the training modules before you can continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowTrainingAlert(false);
              history.push("/training");
            }}
            color="primary"
          >
            Do training
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={showAlertsModal}
        onClose={() => {
          // No close
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Your Alerts</DialogTitle>
        <DialogContent>
          {loadingAlerts ||
            (unseenAlerts.length === 0 && (
              <div>
                <Typography variant="body1" style={{ textAlign: "center" }}>
                  Loading...
                </Typography>
              </div>
            ))}
          {unseenAlerts.map(alert => {
            const maxLength = 100; // Maximum number of characters for the description
            const truncatedDescription =
              alert.description.length > maxLength
                ? `${alert.description.substring(0, maxLength)}...`
                : alert.description;

            return (
              <Card
                key={alert.id}
                style={{
                  marginBottom: 10,
                  borderRadius: 5,
                }}
              >
                <div
                  style={{ padding: 15, marginLeft: 10, fontWeight: "bold" }}
                >
                  {alert.title}
                </div>
                <div
                  style={{ padding: 15, marginLeft: 10 }}
                  dangerouslySetInnerHTML={{ __html: truncatedDescription }}
                />
                <Divider />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    style={{ margin: 15 }}
                    onClick={() => {
                      showAlertFunction(alert);
                    }}
                    color="primary"
                  >
                    View
                  </Button>
                </div>
              </Card>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowAlertsModal(false);
            }}
            color="primary"
          >
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={showActiveAlert}
        onClose={() => {
          // No close
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{activeAlert.title}</DialogTitle>
        {activeAlert.images && activeAlert.images.length > 0 && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            {activeAlert.images.map(image => (
              <img
                src={JSON.parse(image).url}
                alt={JSON.parse(image).name}
                style={{ width: "auto", maxHeight: 250 }}
              />
            ))}
          </div>
        )}
        <DialogContent>
          <div
            style={{ padding: 15, marginLeft: 10 }}
            dangerouslySetInnerHTML={{ __html: activeAlert.description }}
          />
        </DialogContent>
        <Divider />
        <DialogActions
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              setShowActiveAlert(false);
              setAcknowledgingAlert(true);
              updateRow("global_alerts", activeAlert.id, {
                read: true,
                seen_time: new Date(),
              }).then(() => {
                setAcknowledgingAlert(false);
                setShowActiveAlert(false);
                getAlertsFunction();
              });
            }}
            color="primary"
          >
            {acknowledgingAlert ? "Loading..." : "Got It"}
          </Button>
        </DialogActions>
      </Dialog>
      <Helmet>
        <title>Zawadi</title>
      </Helmet>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="fixed"
        className={classes.appBar}
        style={{
          background: `url(${topBg})`,
          backgroundColor: "#fff",
          backgroundSize: "cover",
        }}
      >
        <Toolbar>
          <IconButton
            style={{
              color: "#fff",
            }}
            aria-label="open drawer"
            onClick={toggleDrawerOpen}
            edge="start"
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <img src={logo} width={130} alt="Logo" />
          <Typography
            variant="h6"
            noWrap
            style={{ flex: 1 }}
            className={classes.menuText}
          />
          <Menu
            anchorEl={notifAnchorEl}
            open={Boolean(notifAnchorEl)}
            keepMounted
            onClose={() => setNotifAnchorEl(null)}
            PaperProps={{
              elevation: 10, // Increase the shadow level
              style: {
                borderRadius: "16px", // Set the card rounding
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Initial shadow for cards
              },
            }}
          >
            <DealerNotifications
              showAlertFunction={showAlertFunction}
              allAlerts={allAlerts}
              closeMenu={() => setNotifAnchorEl(null)}
            />
          </Menu>
          {!showWaitingStatus && (
            <div
              style={{
                padding: 5,
                marginRight: 25,
                cursor: "pointer",
                background: "#fff",
                borderRadius: 50,
                boxShadow: "0px 0px 12px black",
              }}
            >
              <IconButton onClick={e => setNotifAnchorEl(e.currentTarget)}>
                <Badge badgeContent={unseenAlerts.length} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </div>
          )}
        </Toolbar>
      </AppBar>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={drawerOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <div style={{ width: "100%", padding: "10px 10px 0 0" }} />
              <IconButton style={{ color: "#fff" }} onClick={handleDrawerClose}>
                {theme.direction === "ltr" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </div>
            <Box className={classes.userDetails}>
              <Box className={classes.detailsAvatar}>
                {currentUserTitle && currentUserTitle[0]}
              </Box>
              <Box
                onClick={() => {
                  const text = `${
                    currentUser.dealer_code ? currentUser.dealer_code : null
                  }: ${currentUserTitle}`;

                  const input = document.createElement("textarea");
                  input.innerHTML = text;
                  document.body.appendChild(input);
                  input.select();
                  const result = document.execCommand("copy");
                  document.body.removeChild(input);

                  // eslint-disable-next-line no-alert
                  alert("Name copied.");
                  return result;
                }}
                style={{ paddingLeft: "1.25rem", cursor: "pointer" }}
              >
                <Typography variant="subtitle2">
                  {currentUser.dealer_code
                    ? `${currentUser.dealer_code}: `
                    : null}
                  {currentUserTitle}
                </Typography>
                <Typography variant="subtitle2" style={{ opacity: 0.5 }}>
                  {currentUserRank}
                </Typography>
                <Typography variant="subtitle2" style={{ opacity: 0.5 }}>
                  Click to Copy
                </Typography>
              </Box>
            </Box>

            <List className={classes.list}>
              {currentUser.rank === 8 || showWaitingStatus
                ? agentDrawerItems &&
                  agentDrawerItems.map(item => (
                    <Link
                      to={item.path}
                      className="drawer-item"
                      key={`${item.title}`}
                      onClick={handleDrawerClose}
                    >
                      <ListItem className={classes.hoverColor}>
                        <ListItemIcon>
                          <FontAwesomeIcon
                            className={classes.icons}
                            icon={item.icon}
                          />
                        </ListItemIcon>
                        <ListItemText className={classes.menuText}>
                          {item.title}
                        </ListItemText>
                      </ListItem>
                    </Link>
                  ))
                : drawerItems &&
                  drawerItems.map(item => (
                    <Link
                      to={item.path}
                      className="drawer-item"
                      key={`${item.title}`}
                      onClick={handleDrawerClose}
                    >
                      <ListItem className={classes.hoverColor}>
                        <ListItemIcon>
                          <FontAwesomeIcon
                            className={classes.icons}
                            icon={item.icon}
                          />
                        </ListItemIcon>
                        <ListItemText className={classes.menuText}>
                          {item.title}
                        </ListItemText>
                      </ListItem>
                    </Link>
                  ))}

              <ListItem
                onClick={handleLogOut}
                style={{ cursor: "pointer" }}
                className={classes.hoverColor}
              >
                <ListItemIcon>
                  <FontAwesomeIcon
                    className={classes.icons}
                    icon={faSignOutAlt}
                  />
                </ListItemIcon>
                <ListItemText className={classes.menuText}>Logout</ListItemText>
              </ListItem>
            </List>
          </Drawer>{" "}
          <Container maxWidth="lg" style={{ padding: -1 }}>
            <main
              className={clsx(classes.content, {
                drawerOpen,
              })}
            >
              <div className={classes.drawerHeader} />
              {showWaitingStatus && <PendingAccount />}{" "}
              <Switch>
                <DealerRoutes showWaitingStatus={showWaitingStatus} />
              </Switch>
            </main>{" "}
          </Container>
        </>
      )}
    </div>
  );
}
