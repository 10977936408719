import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

/**
 * A card component that represents a supplier, with a logo, name, status,
 * and last updated date. It will link to the supplier's custom page if
 * CustomPage is true, otherwise it will link to the supplier's dashboard.
 * If the supplier is not registered, it will not link anywhere.
 *
 * @param {object} props - The props passed to the component.
 * @param {object} props.supplierObject - The supplier object.
 * @param {boolean} props.isActive - Whether the supplier is active.
 * @param {string} props.url - The base URL for the supplier dashboard.
 * @returns {React.ReactElement} - A React element representing the supplier card.
 */
function SupplierTile({ supplierObject, isActive, url }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screen

  const {
    id,
    custom_page: CustomPage,
    custom_page_url: CustomPageUrl,
    logo,
    supplier_status: supplierStatus,
    supplier,
  } = supplierObject;

  // Determine if link is active and if link needs to be redirected to the custom URL
  const linkTo = isActive
    ? CustomPage && url === "/Supplier/"
      ? CustomPageUrl
      : `${url}${id}`
    : "/";

  return (
    <Grid
      item
      style={{
        minWidth: isMobile ? 360 : 405, // Set minWidth for mobile and desktop
      }}
    >
      <Link to={linkTo} style={{ textDecoration: "none" }}>
        <Card
          key={id}
          style={{
            borderRadius: "16px", // Set the card rounding
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Initial shadow
            transition: "transform 0.3s ease, box-shadow 0.3s ease", // Smooth transition for hover effects
          }}
          onMouseEnter={e => {
            e.currentTarget.style.transform = "scale(1.03)";
            e.currentTarget.style.boxShadow = "0px 6px 16px rgba(0, 0, 0, 0.2)";
          }}
          onMouseLeave={e => {
            e.currentTarget.style.transform = "scale(1)";
            e.currentTarget.style.boxShadow = "0px 4px 12px rgba(0, 0, 0, 0.1)";
          }}
        >
          <CardContent
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: 120,
              position: "relative", // Make CardContent the relative container
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: 100,
                    margin: "auto",
                    paddingTop: 12,
                    paddingLeft: 4,
                  }}
                >
                  <img src={logo} alt={logo} width={90} />
                </div>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                item
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "bold",
                      overflow: isMobile ? "hidden" : "visible",
                      textOverflow: isMobile ? "ellipsis" : "unset",
                      whiteSpace: isMobile ? "nowrap" : "normal",
                      width: isMobile ? "180px" : "auto",
                    }}
                  >
                    {supplier}
                  </Typography>
                  <Typography
                    variant="subtitle"
                    color={
                      isActive || supplierStatus === "Cancelled"
                        ? "primary"
                        : "error"
                    }
                  >
                    {isActive ? supplierStatus : "Not Registered"}
                  </Typography>
                  <Typography
                    variant="subtitle"
                    style={{
                      color: "#9E9E9E",
                      fontWeight: "450",
                    }}
                  >
                    {supplierObject?.last_updated !== null &&
                    supplierObject?.last_updated !== undefined
                      ? `Updated Until: ${supplierObject?.last_updated}`
                      : ""}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
}

export default SupplierTile;
