// #useAuthUpdated

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Switch, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  IconButton,
  Menu,
  Badge,
  Container,
  useMediaQuery,
} from "@material-ui/core/";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { Typography } from "@mui/material";
import AdminRoutes from "./routes/AdminRoutes";
import { useAuth } from "../contexts/AuthContext";
import logo from "../img/newZims3.png";
import AdminDrawer from "./layout/AdminDrawer";
import topBg from "../img/appBarBg.png";
import DealerNotifications from "./dealercomps/notifications/DealerNotifications";
import Banner from "./global/Banner";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "#fff",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "none",
  },
  drawerHeader: { ...theme.mixins.toolbar },
  content: {
    flexGrow: 1,
    marginLeft: -drawerWidth,
  },
  mobileContent: { padding: theme.spacing(2) },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  listItem: {
    background: "rgba(39, 115, 82, 0.8)",
  },
}));

/**
 * The main menu component that renders the navigation bar and the main content area.
 * This component is the top level component for the admin dashboard.
 *
 * @returns {React.ReactElement} The main menu component.
 */
export default function MainMenu() {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { logout } = useAuth();
  const history = useHistory();
  // Notifications
  const [notifAnchorEl, setNotifAnchorEl] = useState(null);
  const [notifCount, setNotifCount] = useState(0);
  const isMobile = useMediaQuery("(max-width: 955px)");

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const toggleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLogOut = async () => {
    await logout();
    history.push("/login");
  };

  const updateNotifCount = n => {
    setNotifCount(n);
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Zawadi</title>
      </Helmet>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="fixed"
        variant="elevation"
        className={classes.appBar}
        style={{
          marginTop: -3,
          background: `url(${topBg})`,
          backgroundSize: "cover",
          backgroundColor: "#fff",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Toolbar>
          <IconButton
            style={{
              color: "#fff",
            }}
            aria-label="open drawer"
            onClick={toggleDrawerOpen}
            edge="start"
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <img
            src={logo}
            style={{ maxHeight: 50, marginLeft: ".5rem" }}
            alt="Logo"
          />
          <Typography
            variant="h6"
            noWrap
            style={{ flex: 1 }}
            className={classes.menuText}
          />

          <Menu
            anchorEl={notifAnchorEl}
            open={Boolean(notifAnchorEl)}
            keepMounted
            onClose={() => setNotifAnchorEl(null)}
          >
            <DealerNotifications
              updateCount={updateNotifCount}
              closeMenu={() => setNotifAnchorEl(null)}
            />
          </Menu>

          <div
            style={{
              padding: 5,
              marginRight: 25,
              cursor: "pointer",
              background: "#fff",
              borderRadius: 50,
              boxShadow: "0px 0px 12px black",
            }}
          >
            <IconButton onClick={e => setNotifAnchorEl(e.currentTarget)}>
              <Badge
                overlap="rectangular"
                badgeContent={notifCount}
                color="error"
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <AdminDrawer
        drawerOpen={drawerOpen}
        drawerWidth={drawerWidth}
        handleDrawerClose={handleDrawerClose}
        handleLogOut={handleLogOut}
      />

      <main className={clsx(classes.content)}>
        <div className={classes.drawerHeader} />
        {!isMobile && (
          <main className={clsx(classes.mobileContent)}>
            <Container
              maxWidth="lg"
              //  style={{ padding: -1, transform: "scale(.9)", marginTop: "-2%" }}
            >
              <Switch>
                <AdminRoutes />
              </Switch>
            </Container>{" "}
          </main>
        )}

        {isMobile && (
          <>
            <div style={{ position: "relative", top: "-24px" }}>
              {/* Wrapper to control the position */}
              <Banner imgUrl="https://images.squarespace-cdn.com/content/v1/602d1a7212b33a4973f1ba6a/cd243ba0-b0bb-469d-adef-753e186c6281/Web+Banner+-+655x165px+%281200+x+300+px%29+%281%29.png" />
            </div>

            <main className={clsx(classes.mobileContent)}>
              <Switch>
                <AdminRoutes />
              </Switch>
            </main>
          </>
        )}
      </main>
    </div>
  );
}
